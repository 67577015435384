import React, {
    lazy,
    Suspense,
    useEffect,
    useRef,
    useState
} from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { merge, omit, pick } from 'lodash';
import { GET_CLUSTERS, GET_SUB_CLUSTERS, GET_SUB_CLUSTERS_FOR_LIST } from '../../../graphql';
import { getRoom } from '../../../utils/variables';
import { TopActionBar } from './TopActionBar';
import { RootState } from '../../../store';
import { SelectedClusterView } from './SelectedClusterView';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { ListView, ListViewRef, VIEW_MODES } from './ListView';
import { ClusterWithSubClusters, SubCluster } from './types';
import { FeatureTour } from '../../../components/FeatureTour';
import { aiNarrativesTourSteps, useAINarrativesFeatureTour } from './featureTour';
import { SearchBarNew } from '../../../components/NewFilters';
import { useTotalClusters } from '../../../services/Clusters/useTotalClusters';
import { Loading } from '../../../components/Loading';
import { NarrativeThemeComparison } from './Utils/ComparisonContainer/NarrativeThemeComparison';
import { NarrativeComparison } from './Utils/ComparisonContainer/NarrativeComparison';
import { ClustersSortOptions } from './Utils/ClusterSort';
import { useGlobalFiltersInClusters } from '../../../services/Clusters/GlobalFIltersInClusters';
import { BubbleChartWrapperProps } from './BubbleChart/BubbleChartWrapper';
import { useClusteringStatus } from '../../../services/Clusters/getClusteringStatus';
import { FindNarratives } from './AutomatedNarratives/FindNarratives';
import { FindingNarratives } from './AutomatedNarratives/FindingNarratives';
import { NoNarrativesFound } from './AutomatedNarratives/NoNarrativesFound';
import { EmptySearchResults } from '../../../components/EmptySearchResults';
import { NarrativeAnalysisCompletedAlert } from './AutomatedNarratives/NarrativeAnalysisCompletedAlert';
import { resetSelectedContent } from '../store';

const BubbleChartWrapper = lazy(() => import('./BubbleChart/BubbleChartWrapper').then((module: any) => ({
    default: module.BubbleChartWrapper as React.FC<BubbleChartWrapperProps>
})));

export const AIClustering = () => (
    <FeatureTour tourSteps={aiNarrativesTourSteps}>
        <AIClusteringInner />
    </FeatureTour>
);

const AIClusteringInner = () => {
    const room = getRoom();
    const dispatch = useDispatch();
    const listViewRef = useRef<ListViewRef>(null);
    const { isListViewEnabled } = useSelector((state: RootState) => state.activeTab);
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);
    const { selectedCluster, isParentCluster } = useSelector((state: RootState) => state.selectedCluster);
    const { viewMode } = useSelector((state: RootState) => state.activeAiClusteringTableView);
    const [sort, setSort] = useState({ field: 'volume', order: 'desc', name: 'Most content' });
    const { handleAINarrativesFeatureTour } = useAINarrativesFeatureTour();
    const { counts, loading: totalClustersLoading, hasFilters, refetchClustersCount } = useTotalClusters({
        appliedFilters: {},
        onCompletedCall: handleAINarrativesFeatureTour
    });

    const {
        clusteringHasNotBeenRun,
        timeRemaining,
        data: clusteringStatus,
        loading: clusteringStatusLoading,
        refetchStatus,
        clusteringStartTime
    } = useClusteringStatus({ refetchClustersCount });

    const [allCusterIds, setAllClusterIds] = useState<{
        narrativeTheme: { id: string, title: string, subClustersMappedId: string[] }[]
        narrative: string[][]
    }>({
        narrativeTheme: [],
        narrative: []
    });

    const noClustersFound = !counts?.clusters;

    const centerWrapper = (children: React.ReactNode, paddingVertical = 10) => (
        <div className="px-4">
            <div className={`bg-white rounded px-4 py-${paddingVertical} d-flex align-items-center justify-content-center`}>{children}</div>
        </div>
    );

    const renderAINarratives = () => {
        if (totalClustersLoading || (clusteringStatusLoading && !clusteringStatus)) {
            return (<Loading gif relative height={300} />);
        }

        if (noClustersFound && clusteringHasNotBeenRun) {
            return centerWrapper(<FindNarratives />);
        }

        if (noClustersFound && timeRemaining > 0) {
            return centerWrapper(
                <FindingNarratives timeRemaining={timeRemaining}
                    startTime={clusteringStartTime || Date.now()}
                    refetchStatus={refetchStatus}
                />
            );
        }

        if (noClustersFound && hasFilters) {
            return centerWrapper(
                <EmptySearchResults mainHeading="We couldn't find any results"
                    subtitle="Try adjusting your search or filter options"
                    filters
                />,
                0
            );
        }

        if (noClustersFound) {
            return centerWrapper(
                <NoNarrativesFound lastRunOn={clusteringStartTime} />
            );
        }

        if (isListViewEnabled) {
            return (
                <div className="px-4 mb-4">
                    <ListView ref={listViewRef} sort={pick(sort, ['field', 'order'])} />
                </div>
            );
        }

        return (
            <div className="px-4 ai-clustering position-relative mh-content-page d-flex flex-column">
                {isParentCluster
                    ? (
                        selectedCluster && (
                            <ClusterWrapper id={selectedCluster}
                                renderComponent={(clusterData) => (
                                    <SelectedClusterView cluster={clusterData}
                                        allClusterIds={allCusterIds}
                                        isParent
                                        loading={false}
                                    />
                                )}
                            />
                        )
                    )
                    : (
                        selectedCluster && (
                            <SubClusterWrapper id={selectedCluster}
                                renderComponent={(subClusterData) => (
                                    <SelectedClusterView cluster={subClusterData}
                                        allClusterIds={allCusterIds}
                                        isParent={false}
                                        loading={false}
                                    />
                                )}
                            />
                        )
                    )}
                <div className={`flex-1 mx-n4 transition position-absolute top-left h-100
                     w-100 mh-content-page ${selectedCluster ? 'pointer-events-none' : ''}`}
                >
                    <Suspense fallback={<Loading gif relative height={600} />}>
                        <BubbleChartWrapper allClusterIds={data => setAllClusterIds(data)} />
                    </Suspense>
                </div>
            </div>
        );
    };

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'AI Narratives' }
    ];
    useEffect(() => {
        dispatch(resetSelectedContent());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <main>
            <div className="page-padding position-relative">
                <NarrativeAnalysisCompletedAlert />
                <BreadCrumbs items={bread} />
                <TopActionBar listView={isListViewEnabled} counts={counts} noClustersFound={noClustersFound} />
            </div>
            {(viewMode === VIEW_MODES.THEMES && !(noClustersFound && !hasFilters)) && (
                <div className="mb-2 px-4">
                    <SearchBarNew clusters score listViewRef={listViewRef} isListViewEnabled>
                        {isListViewEnabled ? (
                            <Col xs="auto" className="mb-1">
                                <ClustersSortOptions sort={sort} setSort={setSort} />
                            </Col>
                        ) : null}
                    </SearchBarNew>
                </div>
            )}
            <div className="position-sticky top-0 bg-white z-index-10">
                {
                    selectedNarrativeThemes.length > 0 ? <NarrativeThemeComparison /> : null
                }
                {
                    selectedNarratives.length > 0 ? <NarrativeComparison /> : null
                }
            </div>
            {renderAINarratives()}
        </main>
    );
};

type ClusterWrapperProps = {
    id: string
    renderComponent: (a: ClusterWithSubClusters, loading: boolean, subClustersLoading: boolean) => JSX.Element
    renderLoading?: (a: ClusterWithSubClusters) => JSX.Element
    disableLoading?: boolean
}

export const ClusterWrapper = ({ id, renderComponent, renderLoading, disableLoading }: ClusterWrapperProps) => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_CLUSTERS, {
        variables: {
            limit: 1,
            projectId: room.project_id,
            id
        },
        skip: !id,
        fetchPolicy: 'cache-and-network'
    });
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    const clusterData = data?.getClusters?.clusters ? data?.getClusters?.clusters[0] : undefined;
    const subClusterIds = clusterData?.subClustersMappedId || [];
    const { data: subClustersData, loading: subClusterLoading } = useQuery(GET_SUB_CLUSTERS_FOR_LIST, {
        fetchPolicy: 'cache-and-network',
        skip: !subClusterIds.length,
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, {}), ['labels']),
            ids: subClusterIds
        }
    });
    const cluster = {
        ...clusterData,
        subClusters: subClustersData?.getSubClusters || []
    } as ClusterWithSubClusters;
    if (!disableLoading && (loading || subClusterLoading) && !subClustersData?.getSubClusters) {
        if (renderLoading) {
            return renderLoading(cluster);
        }
        return (<SelectedClusterView cluster={cluster} loading isParent />);
    }
    return renderComponent(cluster, loading, subClusterLoading);
};

type SubClusterWrapperProps = {
    id: string
    renderComponent: (a: SubCluster, loading: boolean) => JSX.Element
    renderLoading?: () => JSX.Element
}

export const SubClusterWrapper = ({ id, renderComponent, renderLoading }: SubClusterWrapperProps) => {
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    const { data, loading } = useQuery(GET_SUB_CLUSTERS, {
        variables: {
            limit: 1,
            ...filters,
            filters: omit(merge(filters.filters, {}), ['labels']),
            ids: [id]
        },
        skip: !id,
        fetchPolicy: 'cache-and-network'
    });
    const subClusterData = data?.getSubClusters ? data?.getSubClusters[0] : undefined;
    if (loading) {
        if (renderLoading) {
            return renderLoading();
        }
        return (<SelectedClusterView cluster={subClusterData as SubCluster} loading isParent={false} />);
    }

    return renderComponent(subClusterData as SubCluster, loading);
};
