import React from 'react';
import { useSelector } from 'react-redux';
import { ContentTableActionsBar } from './ContentTableActionsBar';
import { Pagination } from '../../Molecules';
import { ContentTableContentType } from './index';
import { RootState } from '../../../store';

type PaginationType = {
    count: number
    offset: number
    setOffset: (e: number) => void;
}

type ContentTableViewFooterProps = {
    selectedContentItems: ContentTableContentType[]
    pagination?: PaginationType
    allowSelectBulkActions: boolean
    bulkContentsSelected?: boolean
    total: number
    onSelectAll: () => void
    onBulkContentsSelected?: (value: boolean) => void
    onMarkAsNotThreat?: (ids: string[]) => void
    clearSelection: () => void
    onRemoveContent?: (data: { id: string }[]) => void
    onBatchContentRemove?: () => void,
    contentCount?: number
}

export const ContentTableViewFooter = ({
    selectedContentItems,
    bulkContentsSelected,
    pagination,
    allowSelectBulkActions,
    total,
    onSelectAll,
    onBulkContentsSelected,
    onMarkAsNotThreat,
    clearSelection,
    onRemoveContent,
    onBatchContentRemove,
    contentCount = 0
}: ContentTableViewFooterProps) =>
{
    const { selectedContent } = useSelector((state: RootState) => state.selectedContent);
    return (
        <>
            <div className="border-top bg-white pt-2 pb-11">
                {pagination && (
                    <Pagination total={pagination.count}
                        itemsPerPage={10}
                        offset={pagination.offset}
                        setOffset={pagination.setOffset}
                    />
                )}
            </div>
            {(selectedContent.length > 0 || bulkContentsSelected) && (
                <ContentTableActionsBar selectedContentItems={selectedContentItems}
                    bulkContentsSelected={bulkContentsSelected}
                    clearSelection={clearSelection}
                    allowSelectBulkActions={allowSelectBulkActions}
                    total={total}
                    onSelectAll={onSelectAll}
                    onBulkContentsSelected={onBulkContentsSelected}
                    onMarkAsNotThreat={onMarkAsNotThreat}
                    onRemoveContent={onRemoveContent}
                    onBatchContentRemove={onBatchContentRemove}
                    contentCount={contentCount}
                />
            )}
        </>
    ); };
