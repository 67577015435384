import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GET_WATCHLIST, REMOVE_CONTENT, REMOVE_CONTENT_DJANGO } from '../../graphql';
import { useRemoveFromWatchlist } from '../../services/Threat/removeFromWatchlist';
import { getRoom, getUser } from '../../utils/variables';
import { useGetActiveQueries } from '../../services/getActiveQueries';
import { resetSelectedContent } from '../../pages/Room/store';

export const RemoveContentActions = {
    RemoveContent: 'remove_content',
    MarkAsThreat: 'mark_threat',
    MarkNotAsThreat: 'not_threat'
};

type UseRemoveContentProps = {
    onCompleted?: () => void
    type: string
    ids?: string[]
    action: string
    refetchQueries?: InternalRefetchQueriesInclude
}

type ContentToRemove = {
    id: string
    type?: string
    markThreatInfo?: string[]
}

export const useRemoveContent = ({ onCompleted, type, ids, action, refetchQueries }: UseRemoveContentProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const room = getRoom();
    const user = getUser();
    const { removeFromWatchlist } = useRemoveFromWatchlist({
        situationRoomId: room.id
    } as any);

    const activeQueries = useGetActiveQueries([
        'getThreats',
        'getThreatSummary',
        'getMatchSummary',
        'getContentModalData',
        'getMatches',
        'getWatchlistContent'
    ]);
    const defaultRefetchQueries = [
        { query: GET_WATCHLIST, variables: { situationRoom: room.id } },
        ...activeQueries
    ];

    const [removeContentRequest, { loading, error }] = useMutation(
        (action === RemoveContentActions.RemoveContent) ? REMOVE_CONTENT : REMOVE_CONTENT_DJANGO,
        {
            onCompleted: () => {
                if (onCompleted) onCompleted();
                if (location.pathname.includes('watchlist')) {
                    removeFromWatchlist({
                        contentId: ids
                    });
                }
                dispatch(resetSelectedContent());
            },
            fetchPolicy: 'no-cache',
            ...(refetchQueries ? { refetchQueries } : { refetchQueries: defaultRefetchQueries }),
            awaitRefetchQueries: true
        }
    );

    const removeContent = (removeData: ContentToRemove[]) => {
        removeContentRequest({
            variables: {
                type,
                situationRoomId: room.id,
                projectId: room.project_id,
                data: removeData.map(item => ({
                    _id: item.id,
                    action,
                    created_by: user?.displayName || user?.firstName,
                    type,
                    role: user?.role,
                    mark_threat_info: item.markThreatInfo || []
                }))
            }
        }); };

    return {
        removeContent,
        loading,
        error
    };
};
