import React, { useMemo, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import {
    Nav,
    NavItem,
    Button,
    TabContent,
    TabPane
} from 'reactstrap';
import { useQuery } from '@apollo/client';
import { Cluster, ExtractCluster, SubCluster } from '../types';
import { IconButton } from '../../../../components/Atoms';
import { GET_SUB_CLUSTERS } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { LoadingState as TotalMatchesLoading } from '../../Overview/Visualisation/TotalMatchesAndThreatsOverTime/Loading';
import { ClusterContent } from '../SelectedClusterView/ClusterContent';
import { LoadingState } from '../../Overview/Visualisation/Table/Loading';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { checkGrammer } from '../../../../utils/text/checkGrammer';

type ContentViewProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    data: Cluster[] | SubCluster[];
    loading: boolean;
    isParent: boolean;
    ids?: string[];
};

export const ContentView = ({ isCollapsed, setCollapsable, data, loading, isParent, ids }: ContentViewProps) => {
    const room = getRoom();
    const [activeTab, setActiveTab] = useState(1);
    const narrativeColors = ['square-small-blue', 'square-small-purple', 'square-small-green', 'square-small-orange'];
    const allClusterIds = data.map((item: any) => item.subClustersMappedId).flat(1);
    const { data: subClusterData, loading: subClustersLoading } = useQuery(GET_SUB_CLUSTERS, {
        variables: {
            projectId: room.project_id,
            ids: isParent ? allClusterIds : (ids?.[activeTab - 1] || '')
        },
        skip: !isParent
    });

    const handleTabSwitch = (index: number) => {
        setActiveTab(index);
    };

    const clusterData = useMemo(() => {
        if (isParent) {
            return subClusterData?.getSubClusters || [];
        }
        return data || [];
    }, [data, isParent, subClusterData?.getSubClusters]) as SubCluster[];

    return (
        <div className="mt-4">
            <IconButton
                className="icon-button text-decoration-none"
                icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
                onClick={setCollapsable}
                text={<WidgetTextTooltip widgetName={WidgetName.Content} noMargin className="ml-2" />}
                dataTestid="content"
            />
            <br />
            {isCollapsed && (
                <div>
                    {loading ? <TotalMatchesLoading items={[1]} />
                        : (
                            <div className="border rounded">
                                <Nav tabs className="grid_container_narrative">
                                    {ids?.map((item: string, index: number) => (
                                        <NavItem key={item}
                                            onClick={() => handleTabSwitch(index + 1)}
                                            className={`p-2 ${index !== 3 ? 'border-right' : ''} ${
                                                activeTab === index + 1 ? 'bg-white' : 'border-bottom'}`}
                                        >
                                            <Button color="link"
                                                className={`p-0 mw-0 d-flex align-items-center ${
                                                    activeTab === index + 1 ? 'text-body' : ''}`}
                                            >
                                                <div className={`${narrativeColors[index]} mr-1`} />
                                                {clusterData?.[index]?.contentIds?.length || 0}{' '}
                                                {checkGrammer('Content', clusterData?.[index]?.contentIds?.length > 1)}
                                            </Button>
                                        </NavItem>
                                    )) }
                                </Nav>
                                <TabContent
                                    className="bg-white"
                                    activeTab={activeTab}
                                >
                                    {subClustersLoading ? (
                                        <div className="p-4">
                                            <LoadingState size={10} />
                                        </div>
                                    ) : clusterData?.map((item: SubCluster, index: number) => (
                                        <TabPane tabId={index + 1} key={item.id} className="p-2">
                                            <ClusterContent cluster={item as ExtractCluster<typeof item>}
                                                isParent={false}
                                                countLabel={false}
                                            />

                                        </TabPane>
                                    ))}
                                </TabContent>
                            </div>
                        )}
                </div>

            )}
        </div>
    );
};
